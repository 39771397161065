var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partner-area ptb-64" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "partner-slider-six owl-carousel owl-theme" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-2" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("link.title_1")))])
          ]),
          _vm._m(0)
        ])
      ]),
      _c(
        "div",
        { staticClass: "partner-slider-six owl-carousel owl-theme mt-5" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-2" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("link.title_2")))])
            ]),
            _vm._m(1)
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-10" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-2 col-sm-6 team-logo-img" }, [
          _c("div", { staticClass: "partner-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/project_images/pic/logo-list-1.png"),
                alt: "Image"
              }
            })
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-lg-2 col-sm-6 text-center team-logo-img" },
          [
            _c("div", { staticClass: "partner-item" }, [
              _c("img", {
                attrs: {
                  src: require("assets/project_images/pic/logo-list-3.png"),
                  alt: "Image"
                }
              })
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "col-lg-2 col-sm-6 text-center team-logo-img" },
          [
            _c("div", { staticClass: "partner-item" }, [
              _c("img", {
                attrs: {
                  src: require("assets/project_images/pic/logo-list-4.png"),
                  alt: "Image"
                }
              })
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "col-lg-2 col-sm-6 text-center team-logo-img" },
          [
            _c("div", { staticClass: "partner-item" }, [
              _c("img", {
                attrs: {
                  src: require("assets/project_images/pic/logo-list-5.png"),
                  alt: "Image"
                }
              })
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "col-lg-2 col-sm-6 text-center team-logo-img" },
          [
            _c("div", { staticClass: "partner-item" }, [
              _c("img", {
                attrs: {
                  src: require("assets/project_images/pic/logo-list-6.png"),
                  alt: "Image"
                }
              })
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-10" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-2 text-center team-logo-img" }, [
          _c("div", { staticClass: "partner-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/project_images/pic/UFI_Member-logo-06AB23A13E-seeklogo.com.png"),
                alt: "Image"
              }
            })
          ])
        ]),
        _c("div", { staticClass: "col-lg-2 text-center team-logo-img" }, [
          _c("div", { staticClass: "partner-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/project_images/pic/ICCA.png"),
                alt: "Image"
              }
            })
          ])
        ]),
        _c("div", { staticClass: "col-lg-2 text-center team-logo-img" }, [
          _c("div", { staticClass: "partner-item" }, [
            _c("img", {
              attrs: {
                src: require("assets/project_images/pic/teca-logo.png"),
                alt: "Image"
              }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }